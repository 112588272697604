.service-partners-map-tooltip
  box-shadow: 0px 12px 19px 0px rgba(171,170,169,0.30)
  position: relative
  // transition: visibility 0s, opacity 0.25s linear

  &:after
    top: 100%
    left: 50%
    border: solid transparent
    content: " "
    height: 0
    width: 0
    position: absolute
    pointer-events: none
    border-color: rgba(0, 0, 0, 0)
    border-top-color: #000000
    border-width: 10px
    margin-left: -10px

.styled-select
  overflow: hidden
  border: none
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.25)
  padding-right: 10px
  position: relative
  z-index: 1
  
  &:before 
    content: '\25BC'
    position: absolute
    font-size: 8px
    -webkit-transform: scaleY(.7)
    -moz-transform: scaleY(.7)
    -ms-transform: scaleY(.7)
    -o-transform: scaleY(.7)
    transform: scaleY(.7)
    right: 0
    top: 50%
    margin-top: -4px
    background: transparent
    width: 30px
    text-align: center
    pointer-events: none
    z-index: 2
  

  select 
    background: white 
    border-radius: 0
    padding: 5px /* If you add too much padding here, the options won't show in IE */
    border: 0
    outline: none
    color: inherit
    height: 2.8em
    width: calc(100% + 50px)
    max-width: none !important
  
.service-partner

  a:hover
    box-shadow: 0px 12px 19px 0px rgba(171,170,169,0.30)